import {BunnyElement} from './bunny-element.ts';
import {property} from '../helpers/decorators/PropertyDecoratorHelper.ts';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../helpers/StyleHelper';
import {html} from 'lit';
import {customElement} from 'lit/decorators.js';
import {live} from 'lit-html/directives/live.js';
import {storageBoundQueryString} from '../helpers/decorators/StorageBoundDecoratorHelper.ts';

const DEFAULT_STRING_OPERATORS = ['==', '<=', '>=', '<', '>', 'CONTAINS', 'INCLUDES', '!='];
const DEFAULT_BOOL_OPERATORS = ['==', '!='];
const DEFAULT_DATE_OPERATORS = ['==', '<=', '>=', '<', '>', 'CONTAINS', 'INCLUDES', '!='];
const DEFAULT_NUMBER_OPERATORS = ['==', '<=', '>=', '<', '>', 'CONTAINS', 'INCLUDES', '!='];
const DEFAULT_OPERATORS: Record<FieldType, string[]> = {
    string: DEFAULT_STRING_OPERATORS,
    bool: DEFAULT_BOOL_OPERATORS,
    date: DEFAULT_DATE_OPERATORS,
    number: DEFAULT_NUMBER_OPERATORS,
};

type FieldType = 'string' | 'date' | 'bool' | 'number';


//TODO run type converts for the finished filter
//TODO add date select
//TODO add up/down arrow selection of autocomplete
//TODO filter the auto complete as typing
//TODO array convert, when running CONTAINS
@customElement('component-firestore-collection-list-control-search')
export class ComponentFirestoreCollectionListControlSearch extends BunnyElement {

    @property({type: Array, notify: true})
    @storageBoundQueryString('collection-search', '[]', undefined, true)
    filters: { field: string, comparator: string, value: any }[] = [];

    @property({type: Array})
    fields: { field: string, comparators: string[], type: FieldType }[] = [];

    activeAutoCompleteFilterField?: HTMLElement;

    @property({type: Array})
    autocompleteOptions: string[] = [];

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            :host {
                display: block;
                position: relative;
            }

            .filter {
                border: solid var(--primary-text-color) 1px;
                position: relative;
                display: inline-block;
                border-radius: 5px;

                .internal {
                    padding: 5px 10px;
                    display: inline-block;

                    &:first-child {
                        border-top-left-radius: 5px;
                        border-bottom-left-radius: 5px;
                    }

                    &:first-child {
                        border-top-right-radius: 5px;
                        border-bottom-right-radius: 5px;
                    }
                }

                .field {
                    background: rgba(255, 165, 0, 0.1);

                    &:empty:before {
                        content: '[FIELD]';
                        opacity: .5;
                    }
                }

                .comparator {
                    padding-left: 5px;
                    padding-right: 5px;

                    &:empty:before {
                        content: '[COMPARATOR]';
                        opacity: .5;
                    }
                }

                .value {
                    background: rgba(0, 255, 0, 0.1);

                    &:empty:before {
                        content: '[VALUE]';
                        opacity: .5;
                    }
                }

                .removeFilter {
                    position: absolute;
                    top: 100%;
                    right: 5px;
                    background: var(--attention-color);
                    color: white;
                    border-bottom-left-radius: 100%;
                    border-bottom-right-radius: 100%;
                    padding: 3px;
                    width: 20px;
                    height: 20px;
                    line-height: 20px;
                    text-align: center;
                    font-size: 16px;
                    cursor: pointer;
                    box-shadow: var(--shadow-elevation-2dp-box-shadow);
                    opacity: 0;
                    transition: 0.0625s;
                    transition-delay: .25s;
                    transform: scaleY(0);
                    transform-origin: top;
                    z-index: 1;
                }

                &:hover, &:focus-within {
                    .removeFilter {
                        transition-delay: 0s;
                        opacity: 1;
                        transform: scaleY(1);
                    }
                }
            }

            .autoComplete {
                position: absolute;
                left: 0;
                top: 100%;
                max-height: 300px;
                overflow-y: auto;
                overflow-x: hidden;
                transform-origin: top;
                transition: 0.0625s;
                opacity: 0;
                z-index: 1;
                box-shadow: var(--shadow-elevation-2dp-box-shadow);
                transition-delay: .05s;
                background: white;

                > div {
                    padding: 5px 15px;

                    + div {
                        border-top: solid rgba(32, 32, 32, .1) 1px;
                    }
                }
            }

            :host(:focus-within) {
                .autoComplete {
                    transition-delay: 0s;
                    opacity: 1;
                    transform: scaleY(1);
                }
            }
        `];

    override render() {
        return html`
            <div @input="${this.onContentChanged}"
                 @click="${this.onChildClick}"
                 @keydown="${this.onChildKeyPress}"
                 id="simpleEditor">
                ${this.filters.map(filter => html`
                    <span class="filter">
                        <span class="removeFilter" title="Remove filter [Shift+Del]">X</span>
                        
                        <span class="field internal"
                              contenteditable="true"
                              .textContent="${live(filter.field)}"
                              @focus="${(e: FocusEvent) => this.popualteAutoComplete(e.target as HTMLElement, this.fields.map(_ => _.field))}"></span>
                        <span class="comparator internal"
                              contenteditable="true"
                              .textContent="${filter.comparator}"
                              @focus="${(e: FocusEvent) => this.popualteAutoComplete(e.target as HTMLElement, this.fields.find(_ => _.field === filter.field)?.comparators || [])}"></span>
                        <span class="value internal"
                              contenteditable="true"
                              .textContent="${filter.value}"
                              @focus="${(e: FocusEvent) => this.popualteAutoComplete(e.target as HTMLElement, [])}"></span>
                    </span>
                `)}
                <span class="filter newFilter" style="cursor:pointer; opacity: .6;" tabindex="0">
                    <span class="internal newFilterInternal">Add filter</span>
                </span>
            </div>

            <div class="autoComplete">
                ${this.autocompleteOptions.map(_ => html`
                    <div @click="${() => this.selectAutoComplete(_)}">${_}</div>
                `)}
            </div>
        `;
    }

    connectedCallback() {
        super.connectedCallback();

        this.loadDefaultValuesForFields();
    }

    loadDefaultValuesForFields() {
        for (let field of this.fields) {
            field.type ??= 'string';
            field.comparators ??= DEFAULT_OPERATORS[field.type];
        }
    }

    selectAutoComplete(value: string) {
        let activeAutoCompleteFilterField = this.activeAutoCompleteFilterField;
        if (activeAutoCompleteFilterField) {
            activeAutoCompleteFilterField.focus();
            activeAutoCompleteFilterField.textContent = value;
            this.onContentChanged();
        }
    }

    popualteAutoComplete(filterField: HTMLElement, autoCompleteOptions: string[]) {
        this.activeAutoCompleteFilterField = filterField;
        this.autocompleteOptions = autoCompleteOptions;
    }

    removeFilter(filter: HTMLElement) {
        let removeIndex = [...(filter.parentElement?.children || [])].indexOf(filter);
        if (removeIndex >= 0) {
            this.filters.splice(removeIndex, 1);
            this.requestUpdate('filters');
        }
    }

    buildFiltersFromEditableHTML() {
        this.filters = [...this.shadowRoot?.querySelectorAll('#simpleEditor .filter:not(.newFilter)') as NodeListOf<HTMLElement>]
            .map((filter: HTMLElement) => ({
                field: filter.querySelector('.field')?.textContent || '',
                comparator: filter.querySelector('.comparator')?.textContent || '',
                value: filter.querySelector('.value')?.textContent || '',
            }));
    }

    onContentChanged() {
        this.buildFiltersFromEditableHTML();
    }

    onChildKeyPress(e: KeyboardEvent) {
        if ((e.key === 'ArrowLeft' || e.key === 'ArrowRight') && !e.shiftKey && !e.ctrlKey) {
            let filterInputs = [...this.shadowRoot?.querySelectorAll('.filter .internal:not(.newFilterInternal)') as NodeListOf<HTMLElement>];
            let filterInputIndex = filterInputs.findIndex(_ => e.target === _);
            let selection = (this.shadowRoot as any)?.getSelection() as Selection;
            let selectionRange = selection.getRangeAt(0);


            if (e.key === 'ArrowLeft') {
                let prevInput = filterInputs[filterInputIndex - 1];

                if (selectionRange.startOffset === 0 && prevInput) {
                    prevInput.focus();

                    requestAnimationFrame(() => {
                        let newRange = document.createRange();

                        newRange.setStart(prevInput, 1);
                        newRange.collapse(true);

                        selection.removeAllRanges();
                        selection.addRange(newRange);
                    });
                }
            }

            if (e.key === 'ArrowRight') {
                let nextInput = filterInputs[filterInputIndex + 1];

                if (selectionRange.endContainer instanceof Text && selectionRange.startOffset === selectionRange.endContainer.length && nextInput) {
                    nextInput.focus();

                    requestAnimationFrame(() => {
                        let newRange = document.createRange();

                        newRange.setStart(nextInput, 0);
                        newRange.collapse(true);

                        selection.removeAllRanges();
                        selection.addRange(newRange);
                    });
                }
            }
        }


        if (e.shiftKey && e.key === 'Delete') {
            let currentFilter = (e.target as HTMLElement).closest('.filter');
            this.removeFilter(currentFilter as HTMLElement);
        }
    }


    onChildClick(e: MouseEvent) {
        let myChildren = [...this.shadowRoot?.querySelectorAll('*') as NodeListOf<HTMLElement>];
        let matchedChildren = e.composedPath().filter(_ => _ instanceof HTMLElement && myChildren.includes(_)) as HTMLElement[];


        let matchedChild: HTMLElement | undefined;
        if ((matchedChild = matchedChildren.find(_ => _.matches('.newFilter')))) {
            this.filters.push({field: '', comparator: '==', value: ''});
            this.requestUpdate('filters');
        }

        if ((matchedChild = matchedChildren.find(_ => _.matches('.removeFilter')))) {
            this.removeFilter(matchedChild.parentElement as HTMLElement);
        }
    }

}


declare global {
    interface HTMLElementTagNameMap {
        'component-firestore-collection-list-control-search': ComponentFirestoreCollectionListControlSearch;
    }
}