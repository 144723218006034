import {customElement} from 'lit/decorators.js';
import {ComponentShopCheckout} from './component-shop-checkout';
import {ComponentShopCustomerNewContact} from './component-shop-customer-new-contact';
import {track} from '../../../firebase-analytics/local/helpers/TrackingHelper';
import {shopCall} from '../helpers/ShopHelper';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {Auth} from '../../../auth/local/controllers/Auth';
import {Route} from '../../../routing/local/controllers/Route';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';
import {toastProgressWrapper} from '../../../__internal/local/helpers/decorators/ToastProgressWrapperDecoratorHelper';
import {FriendlyMessage} from '../../../__internal/shared/helpers/ExceptionHelper';
import HistoryHelper from '../../../__internal/local/helpers/HistoryHelper';
import {showToast} from '../../../__internal/local/helpers/ToastHelper';
import {RenderingHelper} from '../../../__internal/local/helpers/RenderingHelper';
import {SurrealDocument} from '../../../__internal/local/controllers/SurrealDocument.ts';
import {FetchMethod} from '../../../__internal/local/controllers/SurrealData.ts';
import {getOrderMeta} from '../helpers/OrderHelper.ts';
import {SurrealCollection} from '../../../__internal/local/controllers/SurrealCollection.ts';
import {callableQuery} from '../../../__internal/local/helpers/SurrealHelper.ts';
import {RecordId} from 'surrealdb';
import {
    AccountShopContactDetailDocument,
    FIRESTORE_COLLECTION_ACCOUNTS,
    FIRESTORE_COLLECTION_ACCOUNTS_SUB_SHOP_CONTACT_DETAILS,
    FIRESTORE_COLLECTION_SHOP_ORDERS,
    FIRESTORE_COLLECTION_SHOP_ORDERS_SUB_TRANSACTIONS,
    ShopOrderDocument,
    ShopOrderTransactionDocument,
} from '../../../../utils/DatabaseTypes.ts';

@customElement('component-shop-checkout-your-details')
class ComponentShopCheckoutYourDetails extends ComponentShopCheckout {
    @property({notify: true})
    route = Route.getInstance(this);

    @property({notify: true})
    auth = Auth.getInstance(this);

    @property({type: String})
    loadOrderId: string;

    @property({type: Object})
    @computed('loadOrderId')
    get order() {
        if (!this.loadOrderId) return undefined;

        return new SurrealDocument<ShopOrderDocument>(
            this,
            '__internal::loadFirestoreDocument',
            [`${FIRESTORE_COLLECTION_SHOP_ORDERS}/${this.loadOrderId}`],
            {method: FetchMethod.LIVE},
        );
    };

    @property({type: String})
    termsAndConditionsLabel: string;

    @property({type: String})
    termsAndConditionsUrl: string;

    @property({type: Boolean})
    populateBillingAddressFromShippingAddress: boolean;

    @property({type: Boolean})
    placingOrder: boolean = false;

    @property({type: Boolean})
    @computed('order', 'placingOrder')
    get isProcessing() {
        if (!this.order) return true;

        return this.order.loading || this.placingOrder;
    }

    @property({type: Object})
    @computed('auth')
    get contacts() {
        return new SurrealCollection<AccountShopContactDetailDocument>(
            this,
            '__internal::loadFirestoreCollection',
            [`${FIRESTORE_COLLECTION_ACCOUNTS}/${this.auth.user?.uid}/${FIRESTORE_COLLECTION_ACCOUNTS_SUB_SHOP_CONTACT_DETAILS}`],
            {method: FetchMethod.LIVE},
        );
    }

    @property({type: Object})
    selectedBillingContact: AccountShopContactDetailDocument;

    @property({type: Object})
    selectedShippingContact: AccountShopContactDetailDocument;

    @property({type: Boolean})
    populateShippingContactFromBillingContact: boolean = true;

    @property({type: Array})
    possiblePaymentHandlers: {
        id: string,
        name: string
    }[];

    @property({type: String})
    paymentHandler: string;

    @property({type: Array})
    @computed('loadOrderId')
    get canceledTransactions() {
        return new SurrealCollection<ShopOrderTransactionDocument>(
            this,
            '__internal::loadFirestoreCollection',
            [
                `${FIRESTORE_COLLECTION_SHOP_ORDERS}/${this.loadOrderId}/${FIRESTORE_COLLECTION_SHOP_ORDERS_SUB_TRANSACTIONS}`,
                {
                    where: [{fieldPath: 'state', opStr: '==', value: 'canceled'}],
                },
            ],
            {
                method: FetchMethod.LIVE,
            },
        );
    }

    @property({type: Array})
    @computed('canceledTransactions')
    get failedTransaction() {
        if (!this.canceledTransactions.data) return undefined;

        return this.canceledTransactions.data
            .sort((a, b) => (b?.created as Date)?.getTime() - (a?.created as Date)?.getTime())
            .find(_ => _.meta?.reason);
    }

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            :host {
                margin-bottom: 100px !important;
            }

            .contentStep h2, .contentStep h3 {
                background-color: var(--primary-text-color);
                color: white;
                margin-left: -15px;
                margin-right: -15px;
                margin-top: -25px !important;
                padding: 15px;
                text-align: center;
            }


            .checkoutPanelWeAccept {
                text-align: center;
                padding: 25px 5px;
            }

            .checkoutPanelWeAccept h3 {
                margin-left: -5px;
                margin-right: -5px;
                padding: 7px;
            }

            .checkoutPanelWeAccept img {
                height: 40px;
                width: 63px;
                box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12), 0 3px 1px -2px rgba(0, 0, 0, .2);
                border-radius: 3px;
            }


            [data-processing="1"] {
                position: relative;
            }

            [data-processing="1"]:after {
                content: 'Processing...';
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                background-color: rgba(255, 255, 255, .85);
                display: flex;
                align-items: center;
                justify-items: center;
                text-align: center;
                flex-direction: column;
                font-size: 20px;
                text-transform: uppercase;
                color: black;
                text-shadow: 0 0 4px white, 0 0 5px white, 0 0 6px white;
                line-height: 58px;
            }
        `,
    ];

    renderFailedTransaction() {
        return html`
            <div class="contentStep" style="margin-bottom: 50px; padding-bottom: 10px">
                <h3 style="background-color: var(--attention-color)">
                    Transaction issue
                </h3>
                <p>
                    ${this.failedTransaction?.meta?.reason}
                </p>
                <small style="display: flex; border-top: solid rgba(32, 32, 32, .1) 1px; padding-top: 10px; margin-top: 15px;">
                    <div>
                        Transaction ID: ${this.failedTransaction?._ref?.id} &middot;
                        <wbr/>
                        At: ${RenderingHelper._dateFormat(this.failedTransaction?.canceled, 'dd/MM/yyyy HH:mm')}
                    </div>
                    <component-dialogable-link href="/contact-us"
                                               style="color: var(--primary-color); margin-left: auto;">
                        Having issues? Contact us!
                    </component-dialogable-link>
                </small>
            </div>
        `;
    }

    renderDetails() {
        return html`
            <h2>Billing details</h2>

            <component-loading .loading="${this.contacts?.loading ?? true}">
                <component-shop-customer-contact-card-collection .contacts="${this.contacts?.data}"
                                                                 .selectedContact="${this.bind.selectedBillingContact}"
                                                                 @select-contact="${this.selectBillingContact}">
                    <component-shop-customer-new-contact
                            slot="createNew"
                            id="newBillingContact"
                            .value="${this.selectedBillingContact}"
                            class="contentStep"
                            style="margin-top: 50px"
                    ></component-shop-customer-new-contact>
                </component-shop-customer-contact-card-collection>
            </component-loading>

            ${this.order?.data?.requiresShipping ? html`
                <h2 style="display: flex">
                    Shipping details

                    <paper-checkbox .checked="${this.bind.populateShippingContactFromBillingContact}"
                                    style="margin-left: auto; text-transform: none; font-size: 18px">
                        Use the same as billing
                    </paper-checkbox>
                </h2>

                ${!this.populateShippingContactFromBillingContact ? html`
                    <component-loading .loading="${this.contacts?.loading ?? true}">
                        <component-shop-customer-contact-card-collection .contacts="${this.contacts?.data}"
                                                                         .selectedCcontact="${this.bind.selectedShippingContact}"
                                                                         @select-contact="${this.selectShippingContact}">
                            <component-shop-customer-new-contact
                                    slot="createNew"
                                    id="newShippingContact"
                                    .value="${this.bind.selectedShippingContact}"
                                    class="contentStep"
                            ></component-shop-customer-new-contact>
                        </component-shop-customer-contact-card-collection>
                    </component-loading>
                ` : undefined}
            ` : undefined}
        `;
    }

    renderOrderSummary() {
        return html`
            <div class="contentStep" data-processing="${this.isProcessing ? 1 : 0}">
                <h2>Order summary</h2>

                <div class="gridContainer row">
                    <div style="--col: 2; font-weight: bold; border-bottom: solid rgba(32, 32, 32, .1) 1px; padding-bottom: 10px;">
                        Qty
                    </div>
                    <div style="--col: 10; font-weight: bold; border-bottom: solid rgba(32, 32, 32, .1) 1px; padding-bottom: 10px;">
                        Item
                    </div>
                    ${Object.values(this.order?.data?.items || {}).map(item => html`
                        <div style="--col: 2">
                            ${item.quantity}
                        </div>
                        <div style="--col: 10; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; ">
                            ${item.name}
                        </div>
                    `)}
                </div>

                <hr>

                <component-shop-price
                        style="font-size: 2em; color: var(--primary-color); display: flex; align-items: center"
                        .value="${this.order?.data?.price.subTotal}">
                    <span slot="prefix"
                          style="color: var(--primary-text-color); font-size: 0.5em; flex: 1">Subtotal: </span>
                </component-shop-price>
                ${this.order?.data?.discounts?.length ? html`
                    Discounts:
                    <ul style="margin-top: 5px; margin-bottom: 5px; padding-left: 0px">
                        ${this.order?.data.discounts.map(item => html`
                            <li>
                                <component-shop-price
                                        style="display: flex; align-items: center"
                                        .value="${item.value}">
                                        <span slot="prefix" style="flex: 1">
                                             - ${item.name} &middot; 
                                        </span>
                                </component-shop-price>
                            </li>
                        `)}
                    </ul>
                ` : undefined}
                <component-shop-price
                        style="font-size: 2em; color: var(--primary-color); display: flex; align-items: center; font-weight: bolder"
                        .value="${this.order?.data?.price.total}">
                    <span slot="prefix"
                          style="color: var(--primary-text-color); font-size: 0.5em; flex: 1">Total: </span>
                </component-shop-price>

                <h3 style="margin-top: 15px!important;">
                    Payment method
                </h3>
                <component-input-radio-group .value="${this.bind.paymentHandler}">
                    ${this.possiblePaymentHandlers?.map(item => html`
                        <component-input-radio-item name="${item.id}" style="display: block">
                            ${item.name}
                        </component-input-radio-item>
                    `)}
                </component-input-radio-group>

                ${this.termsAndConditionsLabel ? html`
                    <p>
                        Your personal data will be used to process your order, support your experience
                        throughout this website, and for other purposes described in our
                        <a href="${this.termsAndConditionsUrl}" target="_blank">${this.termsAndConditionsLabel}</a>.
                    </p>
                ` : undefined}
                <component-button
                        style="display: block; margin: 25px -15px -25px -15px; font-size: 20px; text-align: center"
                        @click="${this.continueToPayment}">
                    Place order
                </component-button>
            </div>

            <div class="contentStep checkoutPanelWeAccept">
                <h3>
                    <small>We accept</small>
                </h3>
                <img src="/images/payment-logo--visa.svg" alt="Visa">
                <img src="/images/payment-logo--mastercard.svg" alt="Mastercard">
                <img src="/images/payment-logo--maestro.svg" alt="Maestro">
                <img src="/images/payment-logo--amex.svg" alt="Amex" style="background-color: #0079c1">
                <!--                <img src="/images/payment-logo&#45;&#45;google-pay.svg" alt="Google Pay">-->
                <img src="/images/payment-logo--apple-pay.svg" alt="Apple Pay">
            </div>

            <div class="contentStep" style="padding: 15px">
                <component-trustpilot-rating
                        style="width: 130px; margin: auto; text-align: center"></component-trustpilot-rating>
            </div>

            <div class="contentStep"
                 style="font-size: 85%; line-height: 1.2; color: #7a7b7d">
                <h4 style="font-weight: normal; color: inherit">
                    Having issues with card payments?
                </h4>

                <p style="margin-bottom: 5px">
                    Some banks block gaming transactions automatically.
                </p>

                <p style="margin-bottom: 5px">
                    Please check with your bank/on your banking app to ensure gambling transactions are not
                    blocked. There are often settings for you to enable/disable gambling within your mobile
                    banking app.
                </p>

                <p style="margin-bottom: 5px">
                    Business debit cards may not work, please check with your bank if you experience a payment
                    decline.
                </p>

                <p style="margin-bottom: 5px">
                    Most personal debit/credit cards will work fine and other reasons for a failed payments can
                    be: incorrect 3 digit security code, end date, 3D secure details and address - so please
                    also check those are correct.
                </p>
            </div>
        `;
    }

    override render() {
        return html`
            ${this.failedTransaction ? this.renderFailedTransaction() : undefined}

            <div class="gridContainer row">
                <div style="--col-md: 8" data-processing="${this.isProcessing ? 1 : 0}">
                    ${this.renderDetails()}
                </div>

                <div style="--col-md: 4">
                    ${this.renderOrderSummary()}
                </div>
            </div>
        `;
    }

    @observe('order')
    orderLoaded(order: ShopOrderDocument) {
        if (!order) return;

        if (order.requiresShipping) {
            this.populateBillingAddressFromShippingAddress = true;
        }
    }

    @observe('populateBillingAddressFromShippingAddress', 'order')
    autoPopulateBillingAddress(populateBillingAddressFromShippingAddress: boolean, order?: SurrealDocument<ShopOrderDocument>) {
        if (!populateBillingAddressFromShippingAddress) return;
        if (!order?.data.requiresShipping) return;

        order.data.billingAddress = {...order.data.shippingAddress};
        this.requestUpdate('order');
    }

    @toastProgressWrapper({
        progressMessage: 'Placing order',
        successMessage: 'Loading payment',
        failedMessage: 'Unable to place order: {{e}}',
    })
    async continueToPayment(_e: Event) {
        if (!this.order?.data?._ref) return;

        try {
            this.placingOrder = true;


            //validate
            for (let newContactPanelId of ['newBillingContact', 'newShippingContact']) {
                let panel = this.shadowRoot?.querySelector<ComponentShopCustomerNewContact>(`#${newContactPanelId}`);
                if (!panel) continue;

                if (!panel.validate()) throw new FriendlyMessage('Please complete your details');
            }


            //save billing contact
            if (!this.selectedBillingContact) throw new FriendlyMessage('Please select your billing details');

            //auto populate shipping contact
            if (!this.selectedShippingContact && this.populateBillingAddressFromShippingAddress) {
                this.selectedShippingContact = this.selectedBillingContact;
            }

            //save shipping contact
            if (this.order?.data.requiresShipping && !this.selectedShippingContact) throw new FriendlyMessage('Please select your shipping details');


            for (let contact of [this.selectedBillingContact, this.selectedBillingContact]) {
                if (contact._ref) continue;

                Object.defineProperty(contact, '_ref', {
                    value: (await callableQuery('__internal::addDoc')(
                        new RecordId(`${FIRESTORE_COLLECTION_ACCOUNTS}_${FIRESTORE_COLLECTION_ACCOUNTS_SUB_SHOP_CONTACT_DETAILS}`, {
                            id: '',
                            parent: this.auth.getInternalUser()?.id,
                        }),
                        contact,
                    )).data._ref,
                });
            }

            let placeOrderStateData = await shopCall.placeOrder(
                this.order?.data._ref.id as string,
                this.paymentHandler,
                this.selectedBillingContact,
                this.selectedShippingContact,
            );

            (window as any).__shopPlaceOrderState = placeOrderStateData.placeOrder;


            HistoryHelper.pushState(
                `/shop/checkout/${this.cartId}/payment`,
                '',
                {placeOrderState: placeOrderStateData.placeOrder},
            );

        } catch (e) {
            this.placingOrder = false;
            throw e;
        }
    }

    private getDefaultContact(): AccountShopContactDetailDocument {
        let accountData = this.auth.getInternalUser();

        return {
            firstName: accountData?.firstName,
            lastName: accountData?.lastName,
            emailAddress: accountData?.emailAddress,
            country: 'United Kingdom',
        } as any;
    }

    selectBillingContact(e: CustomEvent) {
        this.selectedBillingContact = e.detail.contact || this.getDefaultContact();
    }

    selectShippingContact(e: CustomEvent) {
        this.selectedShippingContact = e.detail.contact || this.getDefaultContact();
    }

    @observe('contacts')
    selectDefaultBillingContact(contacts: SurrealCollection<AccountShopContactDetailDocument>) {
        if (!contacts.data) return;
        let selectedBillingContact = this.selectedBillingContact;
        if (selectedBillingContact) return;

        if (!contacts.data.length && !(contacts.data as any)._metadata.fromCache) {
            this.selectedBillingContact = this.getDefaultContact();
            return;
        }


        this.selectedBillingContact = contacts.data[0];
    }

    connectedCallback() {
        super.connectedCallback();

        this.populateOrderState();
    }

    async populateOrderState() {
        if (this.order) {
            this.order.loading = true;
            this.requestUpdate('order');
        }

        try {
            let responseData = await shopCall.startCheckout(
                this.cartId,
                this.route.current.query.orderId,
                getOrderMeta(),
            );

            if (responseData.cartEmpty) {
                HistoryHelper.replaceState('/shop/cart');
                return;
            }

            this.loadOrderId = responseData.orderId;
            this.possiblePaymentHandlers = responseData.possiblePaymentHandlers;
            this.paymentHandler = this.possiblePaymentHandlers[0].id;

        } catch (e: any) {
            showToast(`Failed starting checkout: ${e.message}`);
        }
    }

    @observe('order')
    trackStartOrder(order?: SurrealDocument<ShopOrderDocument>) {
        if (!order?.data) return;
        if ((order.data as any)._metadata.fromCache) return;

        track('shopStartCheckout', {
            items: Object.values(order.data.items || {}).map(_ => ({
                id: _.product.id,
                quantity: _.quantity,
                productPrice: _.productPrice,
                subTotal: _.subTotal,
                discount: _.discount || 0,
                wasOnSale: _.wasOnSale,
                name: _.name,
                categories: _.productCategory,
            })),
            value: order.data.price.total / 100,
            currency: 'GBP',
            orderId: order.data._ref?.id as string,
        });
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-shop-checkout-your-details': ComponentShopCheckoutYourDetails;
    }
}
